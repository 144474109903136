<template>
  <div class="liveDetail">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">直播信息</span>
      </div>
      <div class="news">
        <div class="newsL">
          <div>
            <span class="newsTitle">直播名称：</span>
            <span>{{ detail.liveName }}</span>
          </div>
          <div>
            <span class="newsTitle">直播时间：</span>
            <span>{{ detail.startTime }}</span>
          </div>
          <div>
            <span class="newsTitle">直播方式：</span>
            <span>{{ detail.liveType | filterType }}</span>
          </div>
          <div>
            <span class="newsTitle">直播状态：</span>
            <span>{{ detail.liveStatus | filterStatus }}</span>
          </div>
        </div>
        <div class="newsR">
          <div class="newsRInfo">
            <div class="text">讲师：</div>
            <div class="colorLive" @click="teacher">{{ detail.teacherName }}</div>
          </div>
          <div class="newsRInfo">
            <div class="text">直播介绍：</div>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="introduce" v-html="detail.liveIntroduce"></div>
          </div>
          <div class="newsRInfo">
            <div class="text">直播回放：</div>
            <div class="colorLive" @click="backLive">直播回放</div>
          </div>
          <!-- <div class="newsRInfo">
            <div class="text">讲师介绍：</div>
            <div class="introduce" v-html="detail.teacherIntroduce"></div>
          </div> -->
        </div>
      </div>
    </el-card>
    <el-card class="card">
      <div slot="header" class="clearfix">
        <span class="title">观看人员列表</span>
        <span class="lookPeople">观看人数：{{ list.length }}人</span>
      </div>
      <table-list
        :loading="loading"
        :data="list"
        :columns="columns(this)"
        :pager="pager"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
    </el-card>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    label: '学员姓名',
  },
  {
    prop: 'sex',
    label: '性别',
    formatter: row => {
      if (row.sex == 0) {
        return '未知'
      } else if (row.sex == 1) {
        return '男'
      } else {
        return '女'
      }
    },
  },
  {
    prop: 'phone',
    label: '手机号码',
  },
  {
    prop: 'signUpTime',
    label: '报名时间',
  },
  {
    prop: 'firstWatchTime',
    label: '首次观看直播时间',
  },
  {
    prop: 'duration',
    label: '观看时长（分钟）',
  },
]
import { editSearch, liveRecord } from '@/api/live'
import to from 'await-to'
import TableList from '@/components/TableList'
export default {
  components: {
    TableList,
  },
  filters: {
    filterType(val) {
      if (val == 0) {
        return 'PC'
      } else if (val == 1) {
        return 'APP'
      }
    },
    filterStatus(val) {
      if (val == 0) {
        return '编辑中'
      } else if (val == 1) {
        return '待开始'
      } else if (val == 2) {
        return '直播中'
      } else {
        return '已结束'
      }
    },
  },
  data() {
    return {
      columns,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      detail: {},
      selectList: [],
    }
  },
  created() {
    this.editSearchData()
    this.liveRecordData()
  },
  methods: {
    async liveRecordData() {
      const { size, current } = this.pager
      const [res, err] = await to(liveRecord({ size, current, liveId: this.$route.query.liveId }))
      if (err) return this.$message.warning(err.msg)
      this.list = res.data.list
      this.pager.total = res.data.total
    },
    async editSearchData() {
      const [res, err] = await to(editSearch({ liveId: this.$route.query.liveId }))
      if (err) return this.$message.warning(err.msg)
      this.detail = res.data
    },
    teacher() {
      // this.$router.push({ path: '/teacher/detail', query: { teacherId: this.detail.liveUserId } })
      this.$router.push(`/teacher/${this.detail.liveUserId}`)
    },
    backLive() {
      this.$router.push({ path: '/live/livePlayback', query: { liveId: this.detail.liveId } })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.liveRecordData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.liveRecordData()
    },
  },
}
</script>
<style scoped lang="scss">
.box-card {
  margin-bottom: 20px !important;
  .news {
    display: flex;
    overflow: hidden;
    .newsL {
      width: 20%;
      font-size: 14px;
      div {
        line-height: 30px;
        .newsTitle {
          color: #909399;
        }
      }
    }
    .newsR {
      font-size: 14px;
      width: 80%;
      .newsRInfo {
        display: flex;
        margin-bottom: 28px;
        .introduce {
          line-height: 20px;
          width: calc(100% - 80px);
        }
        .text {
          width: 80px;
          text-align: right;
        }
        .colorLive {
          color: #ff7b33;
          cursor: pointer;
        }
      }
    }
  }
}
.lookPeople {
  font-size: 18px;
  color: #ff7b33;
  float: right;
  padding: 3px 0;
}
</style>
